@import "variables.scss";
@import "~hamburgers/dist/hamburgers.min.css";

@-webkit-keyframes blink-2 {
    0% { opacity: 1 }
    50% { opacity: 0.2 }
    100% { opacity: 1 }
}

@keyframes blink-2 {
    0% { opacity: 1 }
    50% { opacity: 0.2 }
    100% { opacity: 1 }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b2b2b2;
    opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #b2b2b2;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: #b2b2b2;
}

progress::-moz-progress-bar {
    background: #87C53A;
}
progress::-webkit-progress-value {
    background: #87C53A;
}
progress {
    color: #87C53A;
}

#root {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: $font-size-md;
    color: #434343;

    &> .container {
        background-position: center;
        background-size: cover !important;
        background-repeat: no-repeat;
        background: rgba(255, 255, 255, 0.9);
    }
}

h1 { font-size: $font-size-xl }
h2 { font-size: $font-size-lg }
h3 { font-size: $font-size-md }
h4 { font-size: $font-size-sm }
h5 { font-size: $font-size-xs }

input {
    &[type=search] {
        &::after {
            font-family: "Font Awesome 5 Free";
            content: "\f002";
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}

swd-pin-field {
    justify-content: center;
    input {
        height: 50px;
        width: 50px;
        margin: 0 5px;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
    }
}

.bg-none { background: none }

.blink-2 {
    -webkit-animation: blink-2 0.9s infinite both;
    animation: blink-2 0.9s infinite both;
}

.border-none { border: none }
.border-top-none { border-top: none }
.border-right-none { border-right: none }
.border-bottom-none { border-bottom: none }
.border-left-none { border-left: none }

.btn-group {
    .dropdown-menu {
        left: unset;
        right: 0;
    }
}

.carousel {
    .carousel-indicators {
        li {
            width: 7px;
            height: 7px;
            border-radius: 50%;
        }
    }
}

.cursor-pointer { cursor: pointer }

.DateRangePicker {
    .DateRangePickerInput {
        border: none;

        .DateInput {
            .DateInput_input {
                font-size: $font-size-md;
                font-weight: normal;
                padding: 0;
            }
        }
        .DateRangePickerInput_arrow {
            margin: 0 11px 0 0;
        }
    }
}

.dropdown-menu {
    .dropdown-item {
        font-weight: 400;
    }
}

.entry-loading {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 5px;
    right: 25px;
}

.fixed-position-bottom-left {
    bottom: 1vh !important;
    right: unset !important;
    top: unset !important;
    left: 3vw !important;
}

.fixed-position-bottom-right {
    bottom: 1vh !important;
    right: 3vw !important;
    top: unset !important;
    left: unset !important;
}

.flipCountdown {
    .flip-block-time {
        width: 70px;
        margin: 0;

        .title {
            display: none;
        }

        .stage {
            width: 30px;
            height: 40px;
            margin: 0;

            span {
                font-size: 35px;
                color: #343a40!important;
            }
        }
    }
}

.form-check-input {
    position: relative !important;
    margin-top: unset !important;
    margin-left: unset !important;
}

.help {
    margin-right: $spacing-1;
    padding: $spacing-1 $spacing-2;
    border-radius: 2px;
    font-size: $font-size-xs;
}

.img-responsive {
    background-color: rgb(222, 226, 227);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.img-rounded {
    background-size: cover;
    border-radius: 50%;
}

.mm-popup__box {
    top: 70px !important;

    .mm-popup__box__body {
        max-height: 80vh;
        overflow: auto;
    }
}

nav {
    background-color: #00519b;
    border-radius: 0px 0px 12px 12px;

    &.sidebar {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 50px 0 0;
        width: 300px;
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
        z-index: 100;

        &.collapsed {
            left: -300px;
        }

        .navbar-nav {
            &.bottom {
                position: absolute;
                bottom: 0;

                .nav-item {
                    border-top: 1px solid #cfcfcf;
                    border-bottom: none;

                    button {
                        color: #444;
                    }
                }
            }

            .nav-item {
                border-bottom: 1px solid rgb(68, 68, 68);

                &.event-menu {
                    max-height: 330px;
                    overflow-y: auto;
                }
                &.dropdown {
                    .dropdown-toggle {
                        &::after {
                            position: absolute;
                            right: $spacing-3;
                            top: $spacing-4;
                        }
                    }
                    .dropdown-menu {
                        padding: 0;
                        background: none;
                        border: none;

                        .dropdown-item {
                            padding-left: $spacing-5;

                            &:hover {
                                background: none;

                                &>.nav-link {
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    &>.nav-link {
                        color: #FFF;
                    }
                }

                button {
                    &.nav-link {
                        border: none;
                        background: none;
                    }
                }
                .nav-link {
                    color: #FFF;
                }
            }
        }
    }

    .hamburger {
        outline: none;
        padding: 5px;

        &.is-active {
            .hamburger-box {
                .hamburger-inner {
                    &:before, &:after {
                        top: -3px;
                        background-color: #007bff;
                    }
                }
            }
        }

        .hamburger-box {
            width: 20px;
            height: 13px;

            .hamburger-inner {
                &, &:before, &:after {
                    width: 20px;
                    height: 2px;
                    background-color: #007bff;
                }

                &:before {
                    top: 5px;
                }

                &:after {
                    top: 10px;
                }
            }
        }
    }

    .navbar-brand {
        padding-top: 0;
        color: #FFF;
    }

    .navbar-collapse {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: #FFF;
                }
            }

            .dropdown {
                .dropdown-menu {
                    top: 120%;

                    .nav-link {
                        color: #1C1C1C;
                    }
                }
            }
        }
    }
}

.nav {
    &.nav-tabs {
        .nav-item {
            .nav-link {
                background-color: #61849b;
                color: #FFF;
                border-radius: 0;

                &.active {
                    background-color: #FFF;
                    color: #61849b;
                }
            }
        }
    }
}

.rounded-no { border-radius: 0 }
.rounded-5 { border-radius: 5px }
.rounded-20 { border-radius: 20px }

.text-underline { text-decoration: underline }
.text-decoration-none { text-decoration: none }

.slideCountdown {
    font-family: "Open Sans", sans-serif !important;

    .slide-block-time {
        width: 65px;
        margin: 0 5px;
        padding: 15px 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
        background: #FFF;

        &.days {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }
        &.seconds {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
        }
        .title {
            font-size: 10px;
            font-weight: 400;
            color: #969696;
        }

        .stage {
            width: 30px;
            height: 40px;
            margin: 0;

            .mask {
                font-size: 32px;
                font-weight: 600;
                color: #434343 !important;
            }
        }
    }
}

.Toastify {
    .Toastify__toast-container {
        .Toastify__toast {
            border-radius: $border-radius;
        }
    }
}

.zIndex0{ z-index: 0 }

@media (min-width: 425px) {
    #root {
        .container {
            max-width: 100vw;
        }
    }
}

.table-fix-header .table-responsive {height: 165px}
.table-fix-header .table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
    background-color: #fff;
}
.table-fix-header .table-responsive::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
}
.table-fix-header .table-responsive::-webkit-scrollbar-thumb {
    background-color: #5352524A;
}

.table-striped tbody tr:nth-of-type(odd) { background-color: #BABABA }
.table-striped tbody tr:nth-of-type(even) { background-color: #FFF }
